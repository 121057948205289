import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { appRefToIconMap, categoryOrderMap, default as iconDefault } from './appIconMap';

const Styled = styled.div`
  ${style}
`;

export const AppIcons = ({ apps, onClick, appContext, entityContext }) => {
  const { entitySetup } = entityContext;

  const { allApps, connexAppOrigin } = appContext;

  const paymentsURL = entitySetup?.paymentSettings?.paymentsUrl || 'https://portal.fractalpay.com/';

  const filteredApps = React.useMemo(() => {
    if (!apps?.length) return [];

    const caiApps = allApps?.filter(app => app.type === 'CX_APP_CAI').map(app => app.crn);

    const appsWithIcons = apps
      .filter(app => caiApps?.includes?.(app.appRef))
      .map(app => ({
        ...app,
        appIconImage: appRefToIconMap[app.appRef] || iconDefault,
        category:
          Object.keys(categoryOrderMap).find(category => categoryOrderMap[category].includes(app.appRef)) || '5',
      }));

    return appsWithIcons.sort((a, b) => a.category - b.category);
  }, [apps, allApps]);

  return (
    <Styled className={cn('app-icons')} $apps={apps}>
      <div className="app-icons-inner">
        {filteredApps?.map?.(app => {
          const isPaymentsApp = app.appRef === 'Payments';
          return (
            <a
              className="app-icon"
              onClick={e => {
                e.preventDefault();
                if (e.ctrlKey || e.metaKey) {
                  if (isPaymentsApp) {
                    window.open(paymentsURL, '_blank');
                    return;
                  }
                  onClick(app.appRef, true);
                  return;
                }
                if (isPaymentsApp) {
                  window.open(paymentsURL);
                  return;
                }
                onClick(app.appRef);
              }}
              href={isPaymentsApp ? paymentsURL : `${connexAppOrigin}/app/${app.appRef}`}
              key={app.appRef}
              style={{ order: app.category }}
            >
              <img src={app.appIconImage} alt={app.name} />
              {app.appIconImage === iconDefault ? <span className="app-name-default">{app.name}</span> : <></>}
            </a>
          );
        })}
      </div>
    </Styled>
  );
};
