import React from 'react';
import { SearchFieldContainer } from './styles';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { Box } from '@mui/material';

const SearchField = React.memo(({ component, props }) => {
  const { isMd } = useBreakpoints();

  if (!component) {
    return null;
  }

  return (
    <SearchFieldContainer {...(!isMd ? { sx: { marginTop: '0.5rem' } } : { sx: { marginLeft: '0.75rem' } })}>
      {React.cloneElement(component, {
        ...props,
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: !isMd && (
            <InputAdornment position="end" className="collapse-search" onClick={props.toggleSearch}>
              <HighlightOffIcon />
            </InputAdornment>
          ),
        },
      })}
      {!isMd && (
        <Box className="collapse-search" onClick={props.toggleSearch}>
          <HighlightOffIcon />
        </Box>
      )}
    </SearchFieldContainer>
  );
});

export default SearchField;
