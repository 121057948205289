import React, { createContext, useEffect, useContext, useState, useMemo, useCallback } from 'react';
import { getTheme } from '../../design-system/getTheme';
import { globalTokens } from '../../design-system/global-tokens';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { GlobalStyle } from './global-styles/GlobalStyle';

const ThemeContext = createContext();

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    console.trace();
    throw new Error(`useThemeContext cannot be rendered outside of the ThemeContext context provider`);
  }
  return context;
};

export const ThemeProvider = ({ children, themeName, themes = globalTokens }) => {
  const { sendMessage } = usePostMessageContext();
  const storedThemeName = localStorage.getItem('themeName');

  const [_themeName, _setThemeName] = useState(storedThemeName || themeName || 'light');

  // Allows the theme to be change via the themeName prop. Useful in Storybook.
  useEffect(() => {
    if (themeName) {
      _setThemeName(themeName);
      sendMessage({ theme: themeName });
    }
  }, [sendMessage, themeName]);

  const theme = useMemo(() => {
    sendMessage({ theme: _themeName });
    return getTheme(_themeName);
  }, [_themeName, sendMessage]);

  const setThemeName = useCallback((name, persist = true) => {
    if (persist) {
      localStorage.setItem('themeName', name);
    }
    _setThemeName(name);
  }, []);

  return (
    <ThemeContext.Provider value={{ themes, theme, themeName: _themeName, setThemeName }}>
      <GlobalStyle $theme={theme} />
      {children}
    </ThemeContext.Provider>
  );
};
