import { useMediaQuery, useTheme } from '@mui/material';

/* Temporarily disabled 
const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };
*/

export const useBreakpoints = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(`(min-width:${theme.breakpoints.values.xs}px)`);
  const isSm = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const isMd = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const isLg = useMediaQuery(`(min-width:${theme.breakpoints.values.lg}px)`);
  const isXl = useMediaQuery(`(min-width:${theme.breakpoints.values.xl}px)`);

  return { isXs, isSm, isMd, isLg, isXl };
};
