import React from 'react';
import { Slide } from '@mui/material';

import { useBreakpoints } from '../../../hooks/useBreakpoints';

const ResponsiveSlide = ({ children, ...props }) => {
  const { isSm } = useBreakpoints();

  return !isSm ? <Slide {...props}>{children}</Slide> : children;
};

export default ResponsiveSlide;
